/*styles.css*/
* {
    box-sizing: border-box;
  }
  
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    background: #000000;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
      helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  }
  
  .controls {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: #222222;
    opacity: 0.8;
    border-radius: 6px;
    padding: 0.5rem;
  }
  
  .controls h2 {
    color: white;
    font-size: 1rem;
  }
  
  .controls .controlGroup {
    margin-bottom: 0.5rem;
  }
  
  .controls .control {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .controls .control input {
    margin: 0.2rem;
    border: none;
    border-radius: 4px;
    padding: 0.1rem;
  }
  
  .controls .control label {
    font-size: 0.8rem;
    color: white;
    margin-right: 0.5rem;
    flex: 1 1 0;
  }
  